/*eslint perfectionist/sort-objects: "error"*/
import type { UUID } from "utils";

export const TEST_IDS = {
  accountDetailsBreadcrumb: "account-details-breadcrumb",
  accountIdentificationsCardAccountNumber:
    "cash-management-account-details-account-number",
  accountIdentificationsCardAuAccountNumber:
    "account-identifications-card-au-account-number",
  accountIdentificationsCardBsb: "account-identifications-card-bsb",
  accountIdentificationsCardIban: "cash-management-account-details-iban",
  accountIdentificationsCardRouting: "account-identifications-card-routing",
  accountIdentificationsCardSortCode: "cash-management-account-details-routing",
  accountIdentificationsCardSwiftBic:
    "cash-management-account-details-swift-bic",
  accountIdentificationsCardUsAccountNumber:
    "account-identifications-card-Us-account-number",
  accountIdentificationsCardWalletId: "cash-management-wallet-id-content",
  accountIdentifierCardAccountStatus: "cash-management-account-status",
  accountIdentifierCardId: "account-identifier-card-id",
  accountIdentifierCardNickname: "cash-management-account-nickname",
  accountListCreateAccountButton: "button-add-account",
  accountsTab: "accounts-tab",
  breadcrumbPayoutNotifications: "breadcrumb-payout-notifications",
  companyUuid: "cash-management-account-company-uuid",
  companyUuidClipboardButton:
    "cash-management-account-company-uuid-clipboard-button",
  configurationTab: "configuration-tab",
  configureReportNotificationsButton: "configure-report-notifications",
  connectBreadcrumb: "connect-nav-link",
  createAccountAccountNumber: "account-account-number",
  createAccountBreadcrumb: "create-account-breadcrumb",
  createAccountFormAuAccountNumber: "account-account-number",
  createAccountFormBsb: "bsb",
  createAccountFormCurrency: "account-currencies",
  createAccountFormEnableSettlementModelLabel:
    "account-instruct-settlement-batches",
  createAccountFormEnableSettlementModelToggle:
    "cash-management-provider-settlement",
  createAccountFormIban: "account-iban",
  createAccountFormMasterAccountNumber:
    "cash-management-banking-circle-master-account-number",
  createAccountFormNickname: "account-nickname",
  createAccountFormProvider: "cash-management-provider",
  createAccountFormSwiftBic: "account-swift-bic",
  createAccountFormVoltAccountId: "volt-cash-management-bank-volt-account-id",
  createAccountFormWalletId: "cash-management-wallet-id",
  createAccountOpenProviderConfiguration: "button-go-provider-edit",
  createAccountRouting: "account-routing",
  creditNotificationsCardActiveToggle:
    "credit-notifications-card-active-toggle",
  creditNotificationsCardActiveToggleDisabled:
    "credit-notifications-card-active-toggle-disabled",
  creditNotificationsCardActiveToggleEnabled:
    "credit-notifications-card-active-toggle-enabled",
  creditNotificationsCardConfigureButton: "credit-notifications-configure",
  creditNotificationsCardDropdownButton:
    "credit-notifications-card-dropdown-button",
  creditNotificationsCardEditButton: "credit-notifications-card-edit-button",
  creditNotificationsCardFailingNotificationsEmail:
    "cash-management-report-notifications-alert-email",
  creditNotificationsCardNotificationsSecret:
    "cash-management-credit-notifications-secret",
  creditNotificationsCardNotificationsUrl:
    "cash-management-credit-notifications-url",
  creditNotificationsCardStatus: "credit-notifications-card-status",
  creditNotificationsCardTestButton: "credit-notifications-card-test-button",
  creditNotificationsFormEmail: "credit-notifications-form-email",
  creditNotificationsFormUrl: "credit-notifications-form-url",
  filters: {
    amountFrom: "settlements-filters-amount-from-filter",
    amountTo: "settlements-filters-amount-to-filter",
    content: "settlements-filters-content",
    currencyFilter: "settlements-filters-currency-filter",
    modal: "settlements-filters-modal",
    statusFilter: "settlements-filters-status-filter",
    triggerFilter: "settlements-filters-trigger-filter",
  },
  getAccountListBalanceCell: (id: UUID) =>
    `account-list-row-${id}-balance-cell`,
  getAccountListCurrencyCell: (id: UUID) =>
    `account-list-row-${id}-currency-cell`,
  getAccountListRowExpandedContentNextSettlementDate: (id: UUID) =>
    `account-list-row-expanded-content-${id}-next-settlement-date`,
  getAccountListRowExpandedContentSettlementLimit: (id: UUID) =>
    `account-list-row-expanded-content-${id}-settlement-limit`,
  getAccountListRowExpandedContentSettlementSchedule: (id: UUID) =>
    `account-list-row-expanded-content-${id}-settlement-schedule`,
  getAccountListRowExpandedContentSettlementThreshold: (id: UUID) =>
    `account-list-row-expanded-content-${id}-settlement-threshold`,
  getAccountListSettlementAmountCell: (id: UUID) =>
    `account-list-row-${id}-settlement-amount-cell`,
  getAccountListStatusCell: (id: UUID) => `account-list-row-${id}-status-cell`,
  getProviderConfigListProvider: (providerName: string) =>
    `cash-management-configurable-providers-${providerName}`,
  getProviderConfigListProviderConfigured: (providerName: string) =>
    `cash-management-configurable-providers-${providerName}-configured`,
  getProviderConfigListProviderNotConfigured: (providerName: string) =>
    `cash-management-configurable-providers-${providerName}-not-configured`,
  getSettlementRow: (id: UUID) => `settlement-row-${id}`,
  getSettlementRowAmountCell: (id: UUID) => `settlement-row-${id}-amount-cell`,
  getSettlementRowCreatedAtCell: (id: UUID) =>
    `settlement-row-${id}-created-at-cell`,
  getSettlementRowCurrencyCell: (id: UUID) =>
    `settlement-row-${id}-currency-cell`,
  getSettlementRowCustomerCell: (id: UUID) =>
    `settlement-row-${id}--customer-cell`,
  getSettlementRowIdCell: (id: UUID) => `settlement-row-${id}-id-cell`,
  getSettlementRowStatusCell: (id: UUID) => `settlement-row-${id}-status-cell`,
  getSettlementRowTriggerCell: (id: UUID) =>
    `settlement-row-${id}-trigger-cell`,
  getSettlementStatus: (status: string) => `settlement-${status}-status`,
  goBackToConnectSettingsLink: "go-back-to-connect-settings-link",
  isxDomain: "isx-domain",
  isxHmacKey: "isx-hmac-key",
  isxMerchantId: "isx-merchant-id",
  isxNotificationSecret: "isx-notification-secret",
  isxPayoutConfigurationBreadcrumb: "isx-payout-configuration-nav-link",
  isxPayoutConfigurationButton: "isx-payout-configuration",
  isxPrivateKey: "isx-private-key",
  isxSubDomain: "isx-sub-domain",
  locationAndAcceptedPaymentsCardCountry:
    "cash-management-account-location-and-accepted-payments-country",
  locationAndAcceptedPaymentsCardCurrency:
    "cash-management-account-location-and-accepted-payments-currency",
  masterAccountNumber: "cash-management-account-provider-iban-content",
  masterAccountNumberClipboardButton:
    "cash-management-account-provider-iban-clipboard-button",
  masterAccountNumberField:
    "cash-management-banking-circle-master-account-number",
  paymentsBreadcrumb: "payments-breadcrumb-link",
  payoutNotificationsCardConfigureButton: "payout-notifications-configure",
  payoutNotificationsCardDropdownButton:
    "payout-notifications-card-dropdown-button",
  payoutNotificationsCardEditButton: "payout-notifications-card-edit-button",
  payoutNotificationsCardTestButton: "payout-notifications-card-test-button",
  payoutNotificationsFormUrl: "payout-notifications-form-url",
  payoutNotificationsSecret: "payout-notifications-secret",
  payoutNotificationsStatusLabel: "payout-notifications-status-label",
  payoutNotificationsStatusToggle: "payout-notifications-status-toggle",
  payoutNotificationsUrl: "payout-notifications-url",
  providerCardBankingCircleMasterAccountNumber:
    "cash-management-account-provider-iban-content",
  providerCardProviderId: "cash-management-account-provider-id-content",
  providerCardProviderName: "cash-management-account-provider-name",
  providerCardVoltCashManagementVoltAccountId:
    "volt-cash-management-bank-volt-account-id",
  providerConfigCardNotificationSecret:
    "cash-management-config-notificationSecret",
  providerConfigCardRiskScore: "cash-management-config-riskScore",
  providerConfigCardSkeleton: "provider-config-card-skeleton",
  providerConfigCreateBreadcrumb: "breadcrumb-provider-config-create",
  providerConfigDetailsBreadcrumb: "breadcrumb-provider-config-details",
  providerConfigDetailsEditButton: "button-edit-provider",
  providerConfigEditBreadcrumb: "breadcrumb-provider-config-edit",
  providerConfigFormNotificationSecret: "customer-provider-notificationSecret",
  providerConfigFormRiskScore: "customer-provider-riskScore",
  providersTab: "providers-tab",
  reportNotificationsCard: "cash-management-report-notifications-title",
  reportNotificationsCardActiveToggle: "switch-label",
  reportNotificationsCardConfigureButton: "configure-report-notifications",
  reportNotificationsCardDropdownButton:
    "report-notifications-card-dropdown-button",
  reportNotificationsCardEditButton: "report-notifications-card-edit-button",
  reportNotificationsCardFailingNotificationsEmail:
    "cash-management-report-notifications-alert-email",
  reportNotificationsCardNotificationsSecret:
    "cash-management-report-notifications-secret",
  reportNotificationsCardNotificationsUrl:
    "cash-management-report-notifications-url",
  reportNotificationsCardStatus: "report-notifications-card-status",
  reportNotificationsCardTestButton: "report-notifications-card-test-button",
  reportNotificationsFormEmail: "report-notifications-form-email",
  reportNotificationsFormUrl: "report-notifications-form-url",
  reportNotificationsSettings: "breadcrumb-notifications",
  reportNotificationsStatusDisabled:
    "cash-management-report-notifications-status-disabled",
  reportNotificationsStatusEnabled:
    "cash-management-report-notifications-status-enabled",
  reportNotificationStatusTag:
    "cash-management-report-notifications-status-not-configured",
  settlementDetailsAccountName: "settlement-details-account-name",
  settlementDetailsAccountNumber: "settlement-details-account-number",
  settlementDetailsAmount: "settlement-details-amount",
  settlementDetailsCurrency: "settlement-details-currency",
  settlementDetailsCustomerId: "settlement-details-customer-id",
  settlementDetailsCustomerName: "settlement-details-customer-name",
  settlementDetailsHeader: "settlement-details-header",
  settlementDetailsIban: "settlement-details-iban",
  settlementDetailsId: "settlement-details-id",
  settlementDetailsReference: "settlement-details-reference",
  settlementDetailsSortCode: "settlement-details-sort-code",
  settlementDetailsSwift: "settlement-details-swift",
  settlementDetailsTriggerType: "settlement-details-trigger-type",
  settlementModelCardEnableSettlementBatches:
    "settlement-model-card-enable-settlement-batches",
  settlementModelCardTagDisabled:
    "cash-management-account-settlement-model-automatic-enabled",
  settlementModelCardTagEnabled:
    "cash-management-account-settlement-model-automatic-enabled",
  settlementsBreadcrumb: "settlements-breadcrumb-link",
  settlementsDetailsAsideSkeleton: "settlements-details-aside-skeleton",
  settlementsDetailsAsideSkeletonCard:
    "settlements-details-aside-skeleton-card",
  triggerSettlementBatchesButton: "submit-settlement-batch",
  voltCashManagementBankVoltAccountId:
    "volt-cash-management-bank-volt-account-id",
  voltCashManagementBankVoltAccountIdClipboardButton:
    "volt-cash-management-bank-volt-account-id-clipboard-button",
  walletId: "cash-management-wallet-id-content",
  walletIdClipboardButton: "cash-management-wallet-id-clipboard-button",
} as const;
